/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./app/app.scss";

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

// placed here instead of tutorial.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}

.strikethrough {
  text-decoration: line-through;
  color: lightgray !important;
}

ion-toolbar {
  --background: var(--ion-background-color);
}

ion-content {
  // overwrite inline styles
  --offset-bottom: auto!important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

ion-list.no-last-border {
  :last-child {
    --border-style: none !important;
  }
}

ion-list.top-border ion-item:first-of-type {
  border-top: solid 1px rgb(200, 200, 200, 0.6);
  // border-top: 0.55px solid #c8c7cc;
}

ion-list.smaller ion-item {
  --min-height: 1rem;
}

@media only screen and (min-width: 768px) {
  ion-modal {
    --width: 40vw;
    --height: 99vh;
    --border-radius: 8px;  // Optional: to give the modal rounded corners
  }
}

// ion-icon {
//   pointer-events: none;
// }

ion-title.title-large {
  font-size: 20px;
}

.action-sheet-button.red-btn {
  color: var(--ion-color-danger);
  // color: #C3132B;
}

.alert-button.red-btn {
  color: var(--ion-color-danger);
}

.alert-button.secondary-btn {
  color: var(--ion-color-secondary);
}

// Tabs

.tab-bar {
  display: flex;
  justify-content: space-between;
  margin: 5px 15px;
  width: calc(100% - 30px);
  background-color: var(--ion-color-light-tint);
  padding: 5px;
  border-radius: 8px;
}

.tab {
  flex-grow: 1; /* Allow the buttons to fill the available space */
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--ion-color-light-tint);
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: var(--ion-color-secondary);
  color: #fff;
}

// Label

.vertical-align {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

ion-label.smaller {
  font-size: 15px;
}

ion-label,
ion-text,
ion-title {
  cursor: default;
}

.center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.popover-select {
  --max-width: 400px;
  --max-height: 800px;
  --width: 95% !important;
  --height: 95%;
  // margin-top: 1%;
  // margin-left: 2%;
  display: flex;
  align-items: center;  // Centers vertically
  justify-content: center;  // Centers horizontally
  --offset-x: 10px;
}

ion-icon:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

.ios {
  ion-popover {
    --width: 250px;
  }
}

.horse-avatar {
  height: 65px;
  width: 65px;
}
.horse-avatar-big {
  height: 120px;
  width: 120px;
}

.searchbar-input-container {
  margin: 0 7px;
}